<div class="roam-mdc-progress-bar-wrapper">
  @if (loaders.formSubmitting()) {
    <mat-progress-bar mode="indeterminate" />
  }
</div>

<div mat-dialog-title class="dialog-title">
  <div class="justify-between">
    <h1 class="fw-500">
      {{ title }}
    </h1>
    <app-button-save-exit (onClick)="save(true)" />
  </div>
</div>

<div mat-dialog-content class="mt-6 dialog-content" [formGroup]="form">
  <div class="d-flex gap-24 f-column mn-24">
    <div>
      <div class="set-grid set-grid-2 stretch pt-6">
        <div
          class="roam-form-field"
          [class.roam-form-field-error]="
            controls.propertyId.touched && controls.propertyId.invalid
          ">
          <app-roam-select
            placeholder="Association"
            [items]="opts.properties()"
            formControlName="propertyId"
            [loading]="loaders.propertiesLoading()"
            [searchable]="true" />
          <p class="roam-form-error-message">This field is required</p>
        </div>
        <div
          class="roam-form-field"
          [class.roam-form-field-error]="
            controls.unitId.touched && controls.unitId.invalid
          ">
          <app-roam-select
            placeholder="Address"
            formControlName="unitId"
            [loading]="loaders.unitsLoading()"
            [items]="opts.units()"
            [searchable]="true" />
          <p class="roam-form-error-message">This field is required</p>
        </div>
      </div>
      <div class="set-grid set-grid-1 pt-24">
        <div
          class="roam-form-field"
          [class.roam-form-field-error]="
            controls.inspectionDate.touched && controls.inspectionDate.invalid
          ">
          <app-roam-datepicker
            name="Inspection Date"
            formControlName="inspectionDate" />
          <p class="roam-form-error-message">This field is required</p>
        </div>
      </div>
    </div>
    <div>
      <h6 class="title">Select a Category</h6>
      <div class="violation-card mt-16">
        @if (!loaders.typesLoading()) {
          <app-roam-radio-card
            [options]="opts.categories()"
            formControlName="categoryId" />
        } @else {
          <ng-container *ngTemplateOutlet="spinner"></ng-container>
        }
      </div>

      <div class="set-grid set-grid-2 stretch pt-24">
        <div
          class="roam-form-field"
          [class.roam-form-field-error]="
            controls.inspectionIssues.touched &&
            controls.inspectionIssues.invalid
          ">
          <app-roam-select
            isMultiple
            placeholder="Issues"
            formControlName="inspectionIssues"
            [items]="opts.issues()" />
          <p class="roam-form-error-message">This field is required</p>
        </div>
        <div></div>
      </div>

      <div class="set-grid set-grid-2 stretch pt-24">
        <div
          class="roam-form-field"
          [class.roam-form-field-error]="
            controls.letterTemplateId.touched &&
            controls.letterTemplateId.invalid
          ">
          <app-roam-select
            placeholder="Letter"
            formControlName="letterTemplateId"
            [items]="opts.letterTemplates()" />
          <p class="roam-form-error-message">This field is required</p>
        </div>
        <div
          class="roam-form-field"
          [class.roam-form-field-error]="
            controls.status.touched && controls.status.invalid
          ">
          <app-roam-select
            placeholder="Status"
            formControlName="status"
            [items]="opts.statuses()" />
          <p class="roam-form-error-message">This field is required</p>
        </div>
      </div>

      <div class="pt-24">
        <div
          class="roam-form-field"
          [class.roam-form-field-error]="
            controls.text.touched && controls.text.invalid
          ">
          <app-roam-text-area
            name="Additional Information"
            formControlName="text" />
          <p class="roam-form-error-message">This field is required</p>
        </div>
      </div>
    </div>
    <div>
      <h6 class="title">Attachments</h6>
      <div>
        <app-file-uploader
          containerText="Drag Here"
          [(data)]="attachments"
          [config]="modelConfig()" />
      </div>
    </div>

    <app-roam-toggle-slider
      label="Issue Fine"
      name="issuefine"
      formControlName="isIssueFine" />
    @if (form.get("isIssueFine")?.value) {
      <div>
        <div class="d-flex gap-16 mb-24">
          <div class="w-due-date">
            <app-roam-datepicker
              name="Due Date"
              formControlName="fineDueDate"></app-roam-datepicker>
          </div>
          <app-roam-input
            class="w-50"
            name="Amount"
            [currency]="true"
            formControlName="fineAmount"></app-roam-input>
        </div>
        <app-roam-text-area
          name="Memo"
          formControlName="fineMemo"></app-roam-text-area>
      </div>

      <hr class="m-0" />
    }
    <app-roam-toggle-slider
      label="Set Deadline"
      name="deadline"
      formControlName="isDeadline" />

    @if (form.get("isDeadline")?.value) {
      <div class="set-grid set-grid-1">
        <app-roam-datepicker name="Deadline" formControlName="deadlineDate" />
      </div>
      <hr class="m-0" />
    }
  </div>

  <div class="sharing mt-16">
    <h6 class="title">Sharing</h6>
    <sharing-email />
  </div>
</div>

<mat-dialog-actions class="mat-dialog-roam-action pr-28">
  <button-cancel />
  <div class="d-flex gap-16">
    <button
      type="button"
      class="btn btn-o-black"
      (click)="previewInspection()"
      [disabled]="form.invalid">
      Preview
    </button>
    <app-button label="Save" (onClick)="save()" />
  </div>
</mat-dialog-actions>

<ng-template #spinner>
  <div class="justify-center mtb-16">
    <mat-spinner diameter="50" />
  </div>
</ng-template>
